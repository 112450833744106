import firebase from "firebase";

  const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyB2x6KFvJifXCex5C5ukFwMQfB3QxVI8Gg",
    authDomain: "instagram-clone-react-6a1bb.firebaseapp.com",
    projectId: "instagram-clone-react-6a1bb",
    storageBucket: "instagram-clone-react-6a1bb.appspot.com",
    messagingSenderId: "357257832117",
    appId: "1:357257832117:web:0b57c6c8d930e6594ac0fc",
    measurementId: "G-FBRWC057GV"
  });

  const db = firebaseApp.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();

  export { db, auth, storage };